import Paper from '@mui/material/Paper'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import {useCallback, useMemo} from 'react'

const AreaField = ({handleClick, area}) => {
  const displayText = area.trim() ? area : 'Undefined Area Name' // Handle empty or whitespace-only strings
  return (
    <strong>
      <div onClick={handleClick} className='cell-link'>
        {displayText}
      </div>
    </strong>
  )
}

const buildColumns = (handleClickArea) => [
  {field: 'areaIndex', type: 'number', headerName: 'Order', width: 70, cellClassName: 'grid-mono-font', sortable: false},
  {
    field: 'id',
    headerName: 'Area',
    hide: false,
    width: 240,
    sortable: false,
    renderCell: (params) => <AreaField handleClick={() => handleClickArea(params.row.id)} area={params.row.area} />
  },
  {field: 'areaSf', type: 'number', headerName: 'Size', width: 110, cellClassName: 'grid-mono-font', sortable: false},
  {
    field: 'areaSfLabel',
    type: 'string',
    headerName: '',
    width: 70,
    sortable: false,
    cellClassName: 'grid-mono-font'
  },
  {field: 'areaUnits', type: 'number', headerName: 'Quantity', width: 90, cellClassName: 'grid-mono-font', sortable: false},
  {
    field: 'areaUnitLabel',
    type: 'string',
    headerName: '',
    width: 70,
    sortable: false,
    cellClassName: 'grid-mono-font',
    renderCell: (params) => `${params.row.areaUnitLabel !== 'N/A' ? params.row.areaUnitLabel : ''}${params.row.areaUnitLabel !== 'MW' && params.row.areaUnitLabel !== 'N/A' ? 's' : ''}`
  },
  {
    field: 'isRemainderOfBaseBid',
    type: 'string',
    headerName: 'Calculation Logic',
    width: 200,
    sortable: false,
    cellClassName: 'grid-mono-font',
    renderCell: (params) => (
      <>
        {`${params.row.isRemainderOfBaseBid ? 'Value is remainder of base bid' : ''}`} {`${params.row.excludeSfFromProjectTotal ? 'Exclude Sf From Project Total' : ''}`}
      </>
    )
  },
  {field: 'projectContingencyPc', type: 'number', headerName: 'Project Contingency', width: 100, cellClassName: 'grid-mono-font', sortable: false},
  // {field: 'projectContingency', type: 'number', headerName: 'Project Contingency Total', width: 100, cellClassName: 'grid-mono-font', sortable: false},
  {field: 'designContingencyPc', type: 'number', headerName: 'Design Contingency', width: 100, cellClassName: 'grid-mono-font', sortable: false},
  // {field: 'designContingency', type: 'number', headerName: 'Design Contingency Total', width: 100, cellClassName: 'grid-mono-font', sortable: false},
  {field: 'escalationContingencyPc', type: 'number', headerName: 'Escalation Contingency', width: 100, cellClassName: 'grid-mono-font', sortable: false}
  // {field: 'escalationContingency', type: 'number', headerName: 'Escalation Contingency Total', width: 100, cellClassName: 'grid-mono-font', sortable: false}
]

const BreakoutAreasTable = ({bidQuoteAreas, loadBidQuoteArea}) => {
  const handleClickArea = useCallback((targetId) => loadBidQuoteArea(bidQuoteAreas.find((row) => row.id === targetId)), [bidQuoteAreas, loadBidQuoteArea])

  const columns = useMemo(() => buildColumns(handleClickArea), [handleClickArea])
  return (
    <Paper style={{height: 700}}>
      <DataGridPro
        rows={bidQuoteAreas}
        columns={columns}
        checkboxSelection={false}
        columnHeaderHeight={50}
        rowHeight={38}
        disableColumnMenu={true}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1
          },
          '.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitle': {
            textAlign: 'right'
          }
        }}
      />
    </Paper>
  )
}

export default BreakoutAreasTable
