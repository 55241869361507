import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { MutableRefObject, useEffect, useRef } from 'react'
import { ToggleComponent } from '../../../assets/ts/components'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {
  const { config, toggleSidebarState } = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    // Run this, otherwise toggle will not work after removing from DOM
    ToggleComponent.bootstrap()
  }, [toggleRef, props.sidebarRef])

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <div className='d-flex align-items-center'>
        <Link to='/dashboard'>
          {config.layoutType === 'dark-sidebar' ? (
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/Logo@3x.webp')}
              className='h-25px app-sidebar-logo-default'
            />
          ) : (
            <>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Logo@3x.webp')}
                className='h-25px app-sidebar-logo-default theme-light-show'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Logo@3x.webp')}
                className='h-25px app-sidebar-logo-default theme-dark-show'
              />
            </>
          )}
        </Link>
        
        <a 
          href='https://proud-dune-0d985361e.5.azurestaticapps.net/'
          target='_blank'
          rel='noopener noreferrer'
          className='ms-8 text-gray-800 text-hover-primary fs-4 app-sidebar-logo-default'
        >
          Status
        </a>
      </div>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          onClick={toggleSidebarState}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-sm btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate !pr-[60px]',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          // style={{ backgroundColor: '#ff000000 !important, border: 0' }}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName='double-left' className='fs-1 rotate-180' />
        </div>
      )}
    </div>
  )
}

export { SidebarLogo }
